*{
  margin: 0%;
  padding: 0%;
}
body {
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}



